<template>
  <v-container fluid>
    <v-row no-gutters>
      <v-col offset-md="1" offset-lg="2">
        <Container :paddedTopBottom="false">
          <h2>Klaas van der Knaap</h2>
          <p>
            Mijn belangrijkste motivatie om in de beweegzorg te werken is
            simpel. Ik ben gek op bewegen. Of dat nu in groepen is of
            individueel. Een lichaam als geheel of één gewricht in bepaalde
            richting. Van het sociale aspect van in een groep sporten tot een
            specifieke individuele revalidatie, bewegen in de breedste zin van
            het woord. Met de fysiotherapie opleiding in Rotterdam werd er een
            stevige basis gelegd die tijdens de drie jaar fulltime opleiding
            osteopathie in Gent verder werd opgebouwd.
          </p>
          <p>
            Het lijkt mij fantastisch om een gezondheidscentrum te realiseren,
            waar iedereen zichzelf kan zijn en elkaar kan versterken in zijn
            professionaliteit. Momenteel werken we met zes zeer talentvolle
            osteopaten in Bleiswijk. Het doel is om met dit team zoveel mogelijk
            mensen verder te helpen met hun gezondheids- en
            bewegingsvraagstukken. Daan de Bruijn en ik zijn regiovoorzitter van
            de Nederlandse Vereniging van Osteopathie en daarin willen we de
            osteopaten in onze buurt met elkaar verbinden, zodat we weten waar
            elkaars sterke punten zitten en elkaar kunnen helpen als er
            problemen zijn.
          </p>
          <p>
            In mijn vrije tijd vind ik hardlopen, voetbal en bokszaktraining
            heerlijk om te doen. Kijken naar diverse sporten is ook een hobby
            van me.
          </p>
          <p>
            Elke zorgverlener in Nederland moet zijn/haar kennis blijven
            updaten. In de osteopathie zijn er veel inspirerende cursussen en
            opleidingen in binnen en buitenland. In 2012 heb ik Barcelona een
            mooie Evidence based cursus Osteopathie gevolgd bij Christian
            Fossum. Tegelijkertijd was de tweejarige opleiding in Antwerpen van
            Frank de Bakker gestart over de relatie tussen de embryologie en het
            uitgegroeide lichaam. Dit is een zeer inspirerende tijd geweest, die
            een mooi vervolg gaf op de reeds bestaande kennis over het
            bewegingsapparaat. Daarna heb ik die cursus een aantal maal
            herhaald. In 2015 kwam ik terecht bij Panta Rhei voor de opleiding
            osteopathie bij kinderen. Nog iets later volgde ik daar de cursus
            Silver Bullets van Philip van Caille. Hierin werden moeilijke
            casussen besproken en nieuwe oplossingen hiervoor aangereikt uit de
            mechanische, chemische, vasculaire, neurologische en psychosociale
            hoek.
          </p>
          <p>
            Als ik de recensies over onze praktijk lees, zie ik vaak dat er goed
            geluisterd wordt naar de cliënt. Hierin ligt de kern van een goede
            zorgmedewerker; wat heeft de cliënt meegemaakt en wat heeft dat voor
            gevolgen gehad in het lichaam of in de psyche. Als de
            oorzakelijkheden en de tijdlijn in kaart zijn gebracht, dan komen
            daaruit structuren die zijn gaan lijden. Denk dan aan bindweefsel,
            bot, zenuw, spieren, lymfen of bloedvaten. Dit kan ook een
            immuunsysteem of centraal zenuwstelsel zijn. Deze structuren in
            beweging zijn om het herstelproces ten goede te beïnvloeden, elk
            weefsel kent zijn eigen methode en technieken om het in beweging te
            zetten. Ik denk dat onze kennis van het menselijk lichaam met al
            haar schitterende processen onze kernkwaliteit is. Communicatie
            speelt daarin een belangrijke rol. Ik heb 20 jaar lang in diverse
            horecagelegenheden en sportkantines meegeholpen op alle afdelingen.
            Daarin leer je samenwerken in teams, maar ook veel over eten en
            drinken en het omgaan daarmee. Hierin zit een heel belangrijk
            sociaal aspect wat mij dagelijks helpt in de praktijk.
          </p>
          <h3>Specialismen</h3>
          <p>
            Door de vele bijscholingen ter specialisatie kan ik goed omgaan met
            kleine kindjes en lastige vraagstukken als auto-immuunziektes. Ik
            snap beter wat nodig is in een oncologisch traject en ik kan de
            wervelkolom en de omringende gewrichten beter manipuleren. In de
            Silver Bullets opleiding heb ik geleerd om bij complexe casussen
            oplossingen vanuit de mechanische, chemische, vasculaire,
            neurologische en psychosociale hoek te combineren.
          </p>
          <p>
            U kunt bij mij terecht voor uiteenlopende pijnklachten zoals
            rugklachten, hoofdpijn en migraine, gewrichtsklachten. Ook
            chronische ziekten met pijnklachten kan ik vanuit een breed
            perspectief bekijken en behandelen.
          </p>
          <p>
            Baby's en kleine kinderen behandel ik ook veel. Baby's met
            buikkrampen, voorkeurshouding van het hoofd, onrustige baby's en
            baby's die moeite hebben met het aannemen van de borst of fles.
          </p>
          <h3>Opleiding en Nascholing</h3>
          <ul>
            <li>Fysiotherapie aan de Hogeschool van Rotterdam</li>
            <li>The Internation Academy of Osteopathy in Gent</li>
            <li>Kinder- en babyosteopathie</li>
            <li>Geïntegreerde technieken volgens De Bakker</li>
            <li>Toegepaste Kinesiologie</li>
            <li>Barral "nieuwe technieken van de wervelkolom"</li>
            <li>Osteopathie bij kinderen</li>
            <li>Osteopathie in de sport</li>
            <li>Orthomoleculaire voedingsleer</li>
            <li>Silver bullets</li>
            <li>Diverse seminars over voeding en supplementen</li>
          </ul>

          <img src="../../assets/team/klaas.png" alt="Klaas" />
        </Container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Container from "../../components/Container.vue";
export default {
  name: "Klaas",
  components: { Container }
};
</script>

<style lang="less" scoped>
h2 {
  font-size: 2rem;
  text-transform: uppercase;
  margin-bottom: 1rem;
  margin-top: 3rem;
}

h3 {
  font-size: 1.4rem;
  text-transform: uppercase;
  margin-bottom: 0.2rem;
  color: #747769;
  em {
    font-style: normal;
    color: #747769;
  }
}

img {
  max-width: 100%;
}
</style>
